import React from "react"
import MainLayout from "../layouts/MainLayout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./TeamMemberPageTemplate.module.scss"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { useState, useEffect } from "react"
import SEO from "../components/SEO"
import { replaceAll } from "../helpers/replaceAll"

export default function TeamMemberPageTemplate({ data }) {
  const [interview, setInterview] = useState()

  useEffect(() => {
    if (data.markdownRemark.html) {
      const parseHTML = (html, avatarImg) => {
        const HTMLArray = html.match(/<[^>]+>[^<]*<\/[^>]+>/g)

        const paragraphs = HTMLArray.filter(val => val.includes("<p>")).map(
          val => val.replace("<p>", "").replace("</p>", "")
        )
        const questions = HTMLArray.filter(val => val.includes("<h3>")).map(
          val => val.replace("<h3>", "").replace("</h3>", "")
        )

        const paragraphsTexts = []
        for (let i = 0; i < paragraphs.length; i++) {
          paragraphsTexts.push(
            replaceAll(paragraphs[i], "!-break-!", "<br /><br />")
          )
        }

        setInterview(
          paragraphsTexts.map((t, i) => {
            return (
              <div key={questions[i].replace(" ", "-")}>
                <h3>
                  <img src="/images/team/avatars/cryptopia_avatar.png" />
                  {questions[i]}
                </h3>
                <p>
                  <img src={avatarImg} />
                  {parse(t)}
                </p>
              </div>
            )
          })
        )
      }

      parseHTML(
        data.markdownRemark.html,
        data.markdownRemark.frontmatter.avatar_path.childImageSharp.original.src
      )
    }
  }, [])

  return (
    <MainLayout>
      <div className={styles.container}>
        <div className="container">
          <div className="row">
            <div className="col col-lg-12">
              <h1>Meet The Team</h1>
              <h2>Together everyone achieves more</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className={styles.profileContainer}>
                <div className={styles.profile}>
                  <img
                    className={styles.profileAvatar}
                    src={
                      data.markdownRemark.frontmatter.avatar_path
                        .childImageSharp.original.src
                    }
                    alt="Team member avatar"
                  />
                  <h3>{data.markdownRemark.frontmatter.name}</h3>
                  <p className={styles.profileRole}>
                    {data.markdownRemark.frontmatter.role}
                  </p>
                  <p className={styles.profileDescription}>
                    {data.markdownRemark.frontmatter.bio}
                  </p>
                  <ul className={styles.profileLinks}>
                    {data.markdownRemark.frontmatter.linked_in ? (
                      <li>
                        <a
                          target="_blank"
                          href={data.markdownRemark.frontmatter.linked_in}
                        >
                          <img
                            src="/images/icons/icon_social_linkedin_gray.png"
                            alt="linked in icon"
                          />
                        </a>
                      </li>
                    ) : null}
                  </ul>
                  <div className={styles.profileOrnaments}></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {interview ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className={styles.interview}>{interview}</div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </MainLayout>
  )
}

export const query = graphql`
  query teamMember($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        avatar_path {
          id
          childImageSharp {
            original {
              src
            }
          }
        }
        bio
        linked_in
        name
        role
        slug
        stack
        title
        group
      }
    }
  }
`

export const Head = ({ data }) => {
  const schemaMarkup = [
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: data.markdownRemark.frontmatter.name,
      url: `https://cryptopia.com/team/${data.markdownRemark.frontmatter.slug}`,
      image: `https://cryptopia.com/${data.markdownRemark.frontmatter.avatar_path.childImageSharp.original.src}`,
    },
  ]

  return <SEO key="team member page SEO" schemaMarkup={schemaMarkup} />
}
